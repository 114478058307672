@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.color-Box {
  background-color: #39485C;
}

.dark-Box {
  background-color: #192131;
}

.dark-BoxShadow {
  background-color: #222934;
  box-shadow: 0px 0px 20px rgb(79, 57, 57);
}

/* .main {
  height: 100%;
  width: 100%;
  display: absolute;
  background-image: url('./Assets/ArcadeBackground.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
} */

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #222d4d;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: auto; /* Allow content scrolling within the container */
}

.bg-navTop {
  background: #020227;
  box-shadow: 0 0 10px rgb(168, 168, 168);
}

@layer components {

  .text-norm {
    @apply text-white font-Rubik text-[16px];
  }

  .text-subHeader {
    @apply text-white font-Rubik lg:text-[24px] text-[20px];
  }

  .text-bigHeader {
    @apply text-white font-Rubik lg:text-[26px] text-[22px];
  }

  .text-header {
    @apply text-white font-Rubik lg:text-[20px] text-[16px];
  }

  .text-title {
    @apply text-white font-Rubik lg:text-[30px] text-[26px];
  }

  .double-container {
    @apply flex md:flex-row flex-col w-full gap-3;
  }

  .double-single-container {
    @apply mb-4 flex flex-col md:w-[50%];
  }

  .guideBox {
    @apply flex flex-col ss:py-10 p-10 gap-3 rounded-3xl dark-Box w-[80%] items-start;
  }

  .step {
    @apply w-10 h-10 z-10 flex items-center justify-center relative bg-slate-700 rounded-full font-semibold text-white;
  }

  .active .step {
    @apply bg-sky-600;
  }

  .requiredStar-style {
    @apply ml-1 h-[5px] w-[5px];
  }
}

@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center 
    h-12 w-12 mt-2 mb-2 mx-auto  
  bg-gray-400 hover:bg-blue-400 dark:bg-gray-800 
  text-green-500 hover:text-white
    hover:rounded-xl rounded-3xl
    transition-all duration-300 ease-linear
    cursor-pointer shadow-lg;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md
    text-white bg-gray-900 
    text-xs font-bold 
    transition-all duration-100 scale-0 origin-left;
  }

  .sidebar-hr {
    @apply bg-gray-200 dark:bg-gray-800 
    border border-gray-200 dark:border-gray-800 rounded-full
    mx-2;
  }

  .step-item {
    @apply relative flex flex-col justify-center items-center w-44;
  }
}

@layer base {
  .text-title {
    @apply text-white font-Rubik xl:text-[40px] text-[36px];
  }

  .text-norm {
    @apply text-white font-Rubik xl:text-[16px] text-[14px];
  }

  .text-subHeader {
    @apply text-white font-Rubik xl:text-[24px] text-[20px];
  }

  .text-header {
    @apply text-white font-Rubik xl:text-[20px] text-[18px];
  }

  .double-container {
    @apply flex md:flex-row flex-col w-full gap-3;
  }

  .double-single-container {
    @apply mb-4 flex flex-col md:w-[50%];
  }

  .single-container {
    @apply mb-4 flex flex-col;
  }

  .textInput-style {
    @apply border rounded w-full py-2 px-3 bg-white text-gray-700 focus:outline-none
  }

  .requiredStar-style {
    @apply ml-1 h-[5px] w-[5px]
  }

  .step {
    @apply w-10 h-10 z-10 flex items-center justify-center relative bg-slate-700 rounded-full font-semibold text-white;
  }

  .active .step {
    @apply bg-sky-600;
  }

  .requiredStar-style {
    @apply ml-1 h-[5px] w-[5px];
  }
}

@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center 
    h-12 w-12 mt-2 mb-2 mx-auto  
  bg-gray-400 hover:bg-blue-400 dark:bg-gray-800 
  text-green-500 hover:text-white
    hover:rounded-xl rounded-3xl
    transition-all duration-300 ease-linear
    cursor-pointer shadow-lg;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md
    text-white bg-gray-900 
    text-xs font-bold 
    transition-all duration-100 scale-0 origin-left;
  }

  .sidebar-hr {
    @apply bg-gray-200 dark:bg-gray-800 
    border border-gray-200 dark:border-gray-800 rounded-full
    mx-2;
  }
}