.navigation {
  position: relative;
  width: 380px;
  height: 60px;
  background: #192131;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
  
.navigation ul {
  display: flex; 
  width: 300px;
}
  
.navigation ul li {
  position: relative;
  list-style: none;
   /* 359 / 5 = 70 */
  width: 100px;
  height: 60px;
  z-index: 2;
}
  
.navigation ul li .clicker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
  
.navigation ul li .clicker .icon {
  position: relative;
  display: block;
  line-height: 55px;
  font-size: 1.5em;
  text-align: center;
  transition: 0.5s;
}
  
.navigation ul li.active .clicker .icon {
  transform: translateY(-32px)
}
  
.navigation ul li .clicker .text {
  position: absolute;
  background-color: #9b4587;
  color: white;
  padding: 2px 7px;
  border-radius: 12px;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(20px);
}
  
.navigation ul li.active .clicker .text {
  opacity: 1;
  transform: translateY(10px);
}

.indicator {
  position: absolute;
  top: -35px;
  width: 100px;
  height: 70px;
  background-color: #192131;
  border-radius: 50%;
  z-index: 1;
  transition: 0.5s;
}

.indicator::before {
  content: '';
  position: absolute;
  top: 5px;
  left: -28px;
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 15px 18px #192131;
}

.indicator::after {
  content: '';
  position: absolute;
  top: 5px;
  right: -28px;
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 50%;
  box-shadow: -15px 18px #192131;
}

.navigation ul li:nth-child(1).active ~ .indicator {
    transform: translateX(calc(100px * 0));
}

.navigation ul li:nth-child(2).active ~ .indicator {
    transform: translateX(calc(100px * 1));
}

.navigation ul li:nth-child(3).active ~ .indicator {
    transform: translateX(calc(100px * 2));
}

@media (max-width: 760px) { 
  .navigation {
    position: relative;
    width: 240px;
    height: 50px;
    background: #192131;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .navigation ul {
    display: flex; 
    width: 180px;
  }
  
  .navigation ul li {
    position: relative;
    list-style: none;
    /* 180 / 4 = 70 */
    width: 60px;
    height: 40px;
    z-index: 2;
  }
  
  .navigation ul li .clicker {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  
  .navigation ul li .clicker .icon {
    position: relative;
    display: block;
    line-height: 65px;
    font-size: 1.5em;
    text-align: center;
    transition: 0.5s;
  }
  
  .navigation ul li .clicker .text {
    position: absolute;
    background-color: aliceblue;
    color: black;
    padding: 2px 7px;
    border-radius: 12px;
    font-weight: 400;
    font-size: 0.8em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    opacity: 0;
    transform: translateY(20px);
  }

  .indicator {
    position: absolute;
    top: -30px;
    width: 60px;
    height: 60px;
    background-color: #192131;
    border-radius: 70%;
    z-index: 1;
    transition: 0.5s;
}
  

  .indicator::before {
    content: '';
    position: absolute;
    top: 5px;
    left: -28px;
    width: 30px;
    height: 30px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 0px 0px #192131;
}

  .indicator::after {
    content: '';
    position: absolute;
    top: 5px;
    right: -28px;
    width: 30px;
    height: 30px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 0px 0px #192131;
  }

.navigation ul li:nth-child(1).active ~ .indicator {
    transform: translateX(calc(60px * 0));
}

.navigation ul li:nth-child(2).active ~ .indicator {
    transform: translateX(calc(60px * 1));
}

.navigation ul li:nth-child(3).active ~ .indicator {
    transform: translateX(calc(60px * 2));
}

}